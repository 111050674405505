// extracted by mini-css-extract-plugin
export var appImage = "styles-module--appImage--eCq2v";
export var appStoreBox = "styles-module--appStoreBox--4mHvd";
export var bg = "styles-module--bg--IPD68";
export var container = "styles-module--container--A2bYp";
export var contentBox = "styles-module--contentBox--HQUxe";
export var fadeIn = "styles-module--fadeIn--C1SZ5";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--jIabg";
export var googlePlay = "styles-module--googlePlay--s8c3C";
export var header = "styles-module--header--Cxlow";
export var images = "styles-module--images--zUckl";
export var listBox = "styles-module--listBox--LM4aT";
export var rectangleImage1 = "styles-module--rectangleImage1--yiuzg";
export var rectangleImage2 = "styles-module--rectangleImage2--2911l";
export var scaleUp = "styles-module--scaleUp--FhNMh";
export var slideIn = "styles-module--slideIn--xbpHQ";