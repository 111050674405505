import React, { useEffect } from "react";
import * as styles from "./styles.module.scss";
import BasePage from "../components/BasePage";
import SiteGrid from "../components/SiteGrid";
import SiteGutter from "../components/SiteGutter";
import rectangle from "./assets/rectangle.png";
import appStoreImage from "./assets/app-store.png";
import googlePlayImage from "./assets/google-play.png";
import useAnalytics, { pageView } from "../effects/useAnalytics";

function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function isAndroid() {
  return navigator.userAgent.toLowerCase().includes("android");
}

function App({ pageContext }) {
  const {
    images,
    headline,
    intro,
    bulletList,
    appText,
    appStore,
    googlePlayStore
  } = pageContext.slices;

  const analytics = useAnalytics();

  useEffect(() => {
    const isIOSDevice = isIOS();
    const isAndroidDevice = isAndroid();

    pageView(`App Page Viewed`);

    analytics.track(`App Page Viewed`, {
      isIOS: isIOSDevice,
      isAndroid: isAndroidDevice,
      isDesktop: !isAndroidDevice && !isIOSDevice
    });

    if (isIOSDevice) {
      window.location.href = appStore.url;
    }

    if (isAndroidDevice) {
      window.location.href = googlePlayStore.url;
    }
  }, [analytics, appStore, googlePlayStore]);

  return (
    <BasePage pageContext={pageContext}>
      <div className={styles.bg}>
        <SiteGutter>
          <SiteGrid>
            <h1 className="srOnly">The COVE App</h1>
            <div className={styles.container}>
              <div className={styles.images}>
                <div>
                  <img
                    src={
                      images.appImage1.image.gatsbyImageData.images.fallback.src
                    }
                    srcSet={
                      images.appImage1.image.gatsbyImageData.images.fallback
                        .srcSet
                    }
                    sizes={
                      images.appImage1.image.gatsbyImageData.images.fallback
                        .sizes
                    }
                    className={styles.appImage}
                    alt={images.appImage1.image.description}
                  />
                  <img
                    src={rectangle}
                    className={styles.rectangleImage1}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div>
                  <img
                    src={
                      images.appImage2.image.gatsbyImageData.images.fallback.src
                    }
                    srcSet={
                      images.appImage2.image.gatsbyImageData.images.fallback
                        .srcSet
                    }
                    sizes={
                      images.appImage2.image.gatsbyImageData.images.fallback
                        .sizes
                    }
                    className={styles.appImage}
                    alt={images.appImage2.image.description}
                  />

                  <img
                    src={rectangle}
                    className={styles.rectangleImage2}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>

              <div className={styles.contentBox}>
                <h2 className={styles.header}>{headline}</h2>
                <p>{intro}</p>
                <ul className={styles.listBox}>
                  {Object.entries(bulletList).map(([key, bulletText]) => (
                    <li key={key}>
                      {bulletText.url ? (
                        <a
                          className="linkUnderline"
                          target="_blank"
                          rel="noreferrer"
                          href={bulletText.url}
                        >
                          {bulletText.label}
                        </a>
                      ) : (
                        bulletText
                      )}
                    </li>
                  ))}
                </ul>
                <p>{appText}</p>
                <div className={styles.appStoreBox}>
                  <a href={appStore.url} target="_blank" rel="noreferrer">
                    <img src={appStoreImage} alt={appStore.label} />
                  </a>
                  <a
                    href={googlePlayStore.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlayImage}
                      className={styles.googlePlay}
                      alt={googlePlayStore.label}
                    />
                  </a>
                </div>
              </div>
            </div>
          </SiteGrid>
        </SiteGutter>
      </div>
    </BasePage>
  );
}

export default App;
